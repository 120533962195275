<template src="./managePay.html"></template>

<script>
import db_info from '@/assets/db_child-info.json'
import db_infos from '@/assets/db_childs-info.json'
import db_childs_family from '@/assets/db_childs-family.json'

export default {
  name: 'managePay',
  data: () => ({
    db_info: db_info,
    db_infos: db_infos,
    db_childs_family: db_childs_family,


    pay_keys:['kassa_cash', 'kassa_card', 'kassa_bill', 'kassa_zero'],//Ключи события


    rules: [
      (value) => !!value || 'Обязательно',
      (value) => {
        const pattern = /^.*[^A-zА-яЁё. ].*$/;
        return !pattern.test(value) || 'Должны быть только буквы';
      },
      (value) =>
        (value && value.length >= 3) || 'Должно быть минимум 3 символа',
    ],
    emailRules: [
      (v) => !!v || 'E-mail обязателен',
      (v) => /.+@.+\..+/.test(v) || 'Некорректный E-mail',
    ],
    alert: {
      data: {
        type: 'info',
        text: 'Заполните все поля перед сохранением',
      },
      flags: {
        error: false,
        success: false,
        info: true,
      },
      alerts: {
        error: 'Что то пошло не так, попробуйте еще раз',
        success: 'Форма заполнена, можно приступить к оплате',
        info: 'Заполните все поля для перехода к оплате',
      },
    },
    flags: {
      error: false,
      success: false,
      info: true,

      show_tooltip:false,
    },
    alerts: {
      error: 'Что то пошло не так, попробуйте еще раз',
      success: 'Форма заполнена, можно приступить к оплате',
      info: 'Заполните все поля для перехода к оплате',
    },
    date: new Date(),
    raduga: {
      active: false,
      menu: false,
      name: 'Суббота',
      minDate: new Date().toISOString().slice(0, 10),
      dateDays: new Date().toISOString().substr(0, 10),
      // foreWeak: [],
      dates:[
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1)
      ],
      days: ["Sat"]
    },
    db_date: new Date().toISOString().slice(0, 10),
    inputsList: {
      db_parent: {
        id: 'db_parent',
        show: true,
        name: 'ФИО родителя',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-multiple',
        col: 6,
      },
      db_ch_name: {
        id: 'db_ch_name',
        show: true,
        name: 'Имя ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_ch_family: {
        id: 'db_ch_family',
        show: true,
        name: 'Фамилия ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_ch_father: {
        id: 'db_ch_father',
        show: true,
        name: 'Отчество ребенка',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-child-outline',
        col: 4,
      },
      db_mail: {
        id: 'db_mail',
        show: true,
        name: 'E-mail',
        type: 'input',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-email-fast-outline',
        col: 6,
      },
      db_sum: {
        id: 'db_sum',
        show: true,
        name: 'Сумма',
        type: 'input',
        value: '0',
        placeholder: '',
        required: false,
        icon: 'mdi-cash',
        col: 6,
      },
      db_mounth: {
        id: 'db_mounth',
        show: true,
        name: 'Месяц',
        type: 'select',
        menu: false,
        modal: false,
        minDate: new Date().toISOString().slice(0, 10),
        value: new Date().toISOString().substr(0, 7),
        placeholder: '',
        required: false,
        icon: 'mdi-phone',
        col: 6,
        options: [],
      },
      db_group: {
        id: 'db_group',
        show: true,
        name: 'Коллектив',
        type: 'select',
        value: '',
        placeholder: '',
        required: true,
        icon: 'mdi-account-group-outline',
        col: 12,
        options: [],
      },
    },
    emptyChildren: [],
    children: [],
    childrenFamilys:[],
    childFIO: ['', '', ''],
    childReady: false,
    orderData: {
      pant: '',
      child: '',
      mail: '',
      price: '',
      date: '',
      group: '',
      subGroup: '',
    },

    payment_created: false,
    query_id: 0,
    payment_id: 0,
    bank_link: 0,

    snackbar: false,
    snack_text: '',

    valid: false,
  }),
  watch: {
    orderData: {
      handler() {
        let check = true;
        for(let key in this.orderData){
          if(this.orderData[key] === "") check = false
        }
        this.valid,this.alert.flags.success = check;
      },
      deep: true
    },
    // orderData:function(){
    //   console.log("Заполнилось что то")
    //   console.log(this.orderData)
    //   let check = true;
    //   for(let key in this.orderData){
    //     if(this.orderData[key] === "") check = false
    //   }
    //   this.valid,this.alert.flags.success = check;
    // },
    childFIO: function () {
      // watch it
      if (
          this.childFIO[0].length > 3 &&
          this.childFIO[1].length > 2 &&
          this.childFIO[2].length > 3
      ) {
        console.log(this.childFIO);
        this.orderData.child =
            this.childFIO[0].trim() +
            ' ' +
            this.childFIO[1].trim() +
            ' ' +
            this.childFIO[2].trim();
      } else {
        this.orderData.child = '';
      }
    },
  },
  created() {
    this.groupLoad();
  },
  methods: {
    loadChildInfo(info){
      let fio_arr
      for(let key in info){
        switch(key){
          case "child":
            fio_arr = info[key].split(' ')
            this.childFIO[0] = fio_arr[0]
            this.childFIO[1] = fio_arr[1]
            this.childFIO[2] = fio_arr[2]
            this.orderData[key] = info[key]
          break;
          case 'group':
            this.orderData[key] = Number(info[key])
            this.subGroup('',info[key])
          break;
          case 'subGroup':
            if(db_info[key] !== ""){
              this.orderData[key] = Number(info[key])
              this.subGroup('child')
            }
          break;
          default:
            this.orderData[key] = info[key]
        }
      }
      this.clearChild()
    },
    clearChild(){
      console.log('чистим чистим')
      this.childrenFamilys.splice(0)
    },
    findChild(){//TODO переименовать, в зависимости от этапа вызова
      if(this.childFIO[0].length >= 3){
        // console.log(this.childFIO[0])
        // this.orderData db_info
        // console.log(db_infos)
        this.clearChild()
        db_infos.forEach(el=>{
          this.childrenFamilys.push(el)
        })
        // this.childrenFamilys = db_infos
      }
    },
    // validate() {
    //   this.$refs.form.validate();
    // },

    allowedDaysNew(val){//поиск только суббот для календаря
      let date = new Date(val).toString().substr(0, 3)
      if(date === 'Sat') return val
    },

    getNextSatSelect(date = new Date()){//выбор ближайшей субботы по умолчанию
      let sat = new Date(date.setDate(date.getDate() + ((7 - date.getUTCDay() + 6) % 7 || 7))).toISOString().slice(0, 10)
      return sat
    },

    groupLoad() {//загрузка списка групп
      let pr2 = this.$store.state.api_dk.LoadTeam();
      pr2.then((data) => {
        this.inputsList.db_group.options = data;
      })
    },
    subGroup(type,group_id = this.orderData.group) {//разблокирование подстановка подгрупп в поле
      console.log(type)
      // this.inputsList.db_group.options.forEach(el=>{
      //   console.log(el.id)
      // })
      // console.log(typeof group_id)
      group_id = Number(group_id)
      console.log(group_id)
      let item = this.inputsList.db_group.options.find(
        (el) => el.id === group_id
      );
      let typeGroup, child;
      console.log(item)
      switch (type) {
        case 'child':
          child = this.children.find((el) => el.id === this.orderData.subGroup);
          typeGroup = child.type;
        break;
        default:
          typeGroup = item.type;
      }
      console.log(typeGroup)
      switch (typeGroup) {
        case 1://Коллектив без групп
          this.children = [];
          this.childReady = false;
          this.orderData.subGroup = 0;
          if (item.id === 26 && item.name === 'Радуга') {
            this.orderData.date = this.getNextSatSelect();
            this.raduga.active = true;
          } else {
            this.orderData.date = new Date().toISOString().slice(0, 7)
            this.raduga.active = false;
          }
          this.priceFind()
        break;
        case 2://Коллектив с группами
          this.children = [];
          this.children = item.child;
          this.childReady = true;
          this.orderData.price = '';
          this.raduga.active = false;
          break;
        case 3://Группа коллектива
          this.priceFind()
        break;
        default:
          console.log('Чет не нашлось');
          this.childReady = false;
          this.raduga.active = false;
      }
    },
    flagsChecker(text) {//смена статусов
      for (let key in this.alert.flags) {
        this.alert.flags[key] = false;
      }
      switch (text) {
        case 'success':
          this.alert.flags.success = true;
          this.alert.data.text = this.alert.alerts.success;
          this.alert.data.type = text;
          break;
        case 'error':
          this.alert.flags.error = true;
          this.alert.data.text = this.alert.alerts.error;
          this.alert.data.type = text;
          break;
        default:
          this.alert.flags.inform = true;
          this.alert.data.text = this.alert.alerts.info;
          this.alert.data.type = 'info';
      }
    },
    priceFind(){//поиск цены по дате и группе
      console.log("Запускается ли поиск цены?")

      if(this.orderData.date === '') return

      let id
      let date = this.orderData.date
      switch(this.orderData.date.length){
        case 7:
          date += '-01%2000:00:02'
          break;
        case 10:
          date += '%2000:00:02'
          break;
      }

      id = this.childReady ? this.orderData.subGroup : this.orderData.group
      if(id === '') return;
      console.log("id: " + id + " date: " + date)
      let pr2 = this.$store.state.api_dk.FindTeamPriceDay(id,date);
      pr2.then((data) => {
        this.orderData.price = data.price;
      })
    },
    formChecker(e) {//проверка формы TODO проверить
      console.log(e);
      console.log(this.orderData)
      let allCheck = true;
      let error = false;
      let valid = /.+@.+\..+/;

      for (let key in this.orderData) {
        if (this.orderData[key] === '') {
          allCheck = false;
        }
        switch (key) {
          case 'pant':
          case 'child':
            this.orderData[key] = this.orderData[key].trim();
            break;
          case 'mail':
            this.orderData[key] = this.orderData[key].trim();
            if (
              !valid.test(this.orderData[key]) &&
              this.orderData[key].length > 0
            ) {
              allCheck = false;
              error = true;
            }
            break;
        }
      }

      if (!this.orderData.price > 0) {
        allCheck = false;
      }

      if (allCheck && !error) {
        this.flagsChecker('success');
      } else if (!allCheck && error) {
        this.flagsChecker('error');
      } else {
        this.flagsChecker();
      }
    },
    goToOrder(type) {
      console.log(type)
      console.log(
        'Пока отрабатываем ошибку, но она нужна на случай если не получилось отправить пользователя на оплату'
      );
      console.log(this.orderData);

      // console.log(this.orderData.date);
      // console.log(this.orderData.date.length);

      let date_arr = this.orderData.date.split('-');//Разделение целой даты на [год][месяц][день]
      if (this.orderData.date.length === 7) {
        date_arr.push('01');
      }

      let team = this.orderData.subGroup;
      if (team === 0) {
        team = this.orderData.group;
      }

      let query_data = {
        data: {
          base: {
            type:type,

            parent: this.orderData.pant,
            children: this.orderData.child,
            email: this.orderData.mail,
            year: date_arr['0'],
            month: date_arr['1'],
            day: date_arr['2'],
            team: team,
          },
        },
      };

      console.log(query_data);
      // let pr2,pr3,payment_data;
      let pr2;

          pr2 = this.$store.state.api_dk.AddQuery(query_data);
          pr2.then((data) => {
            console.log(data)
            // if (data.b) {}
          })

    },
  },
};
</script>

<style scoped>
.r {
}
</style>
